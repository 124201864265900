<template>
  <v-card flat>
    <v-row class="pr-3 mt-3">
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <v-date-picker
          no-title
          v-model="picker"
          locale="id"
          :picker-date.sync="currentDate"
          :allowed-dates="allowedDates"
          :events="arrayEvents"
        ></v-date-picker>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <v-card outlined class="rounded-lg pa-1" v-if="schedule">
          <v-row>
            <v-col
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              class="py-0"
            >
              <v-card-actions>
                <p class="ma-0 subtitle-2 font-weight-regular">
                  <v-avatar color="#1792E6" size="10" class="mr-2"></v-avatar>
                  <span class="font-weight-bold">{{
                    schedule.schedule_type
                  }}</span>
                  {{ schedule.jam_kerja }}
                </p>
              </v-card-actions>
              <div v-if="id == schedule.NIP && isNow(schedule.attendance_date)">
                <v-btn
                  class="float-right mr-2 mb-2"
                  v-if="schedule.check_in_time == 'N/A'"
                  small
                  color="success"
                  @click="$emit('on-check-in', schedule, true)"
                >
                  Absen Masuk
                </v-btn>
                <v-btn
                  class="float-right mr-2 mb-2"
                  v-else-if="schedule.check_out_time == 'N/A'"
                  small
                  color="success"
                  @click="$emit('on-check-in', schedule, true)"
                >
                  Absen Keluar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapSummaryRootField } from "@/store/helpers";

export default {
  props: {
    currentDate: { type: String, required: false }
  },
  data() {
    return {
      id: this.$route.params ? this.$route.params.employeeId : null,
      picker: new Date().toISOString().substr(0, 10),
      schedules: [],
      arrayEvents: null,
      schedule: null
    };
  },
  computed: {
    ...mapSummaryRootField({
      historyAttendances: "historyAttendances"
    })
  },
  watch: {
    currentDate(val) {
      this.$emit("on-change-date", val);
    },
    picker(val) {
      this.schedule = this.historyAttendances.find(
        d => d.attendance_date == val
      );
    },
    historyAttendances(val) {
      this.computeArrayEvent(val);
    }
  },
  methods: {
    // Service
    isNow(date) {
      return this.$moment(date).isSame(
        new Date("2021-05-04").toISOString().substr(0, 10)
      );
    },
    allowedDates(val) {
      const item =
        this.historyAttendances &&
        this.historyAttendances.find(
          d => d.attendance_date == val && d.status1 == "100001"
        );
      if (item) return false;
      return true;
    },
    computeArrayEvent(val) {
      let arrayEvents = val.filter(d => d.status1 != "100001");
      this.arrayEvents = arrayEvents.map(item => {
        const d = new Date(item.attendance_date);
        return d.toISOString().substr(0, 10);
      });
    }
  },
  mounted() {
    this.computeArrayEvent(this.historyAttendances);
  }
};
</script>
